<template>
  <v-container fluid class="down-top-padding">
    <v-row>
<v-col cols="3"></v-col>
      <v-col cols="6"> </v-col>
      <v-col cols="3"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "StarterPage",
  data: () => ({

  }),
  mounted() {
    this.$router.push('edu-org/list')
  },
};
</script>
